import { splitDeviceChildren } from "./deviceDataConverter";
import { format } from "date-fns";
import { getCurrentTime } from "./timeHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getQueue, setQueue } from "../redux/dataSlices/queueSlice";

export const QueueHandler = () => {
    const playlistQueue = useSelector(getQueue);
    const dispatch = useDispatch()

    function createQueue(device) {
        const newQueue = {};
        const [schedules] = splitDeviceChildren(device);
        const currentDay = format(new Date(), 'EEEE');

        // Turn all strings in array to uppercase, then do the check to prevent inconsistent values being missed
        const scheduleToday = schedules.find(schedule => schedule.day.map(day => day.toUpperCase()).includes(currentDay.toUpperCase()));

        // Populate queue with hours as keys
        for (let hour = 0; hour < 24; hour++) {
            const hourFormatted = hour.toString().padStart(2, '0') + ":00";
            newQueue[hourFormatted] = null;
        }

        // Iterate through each playlist
        scheduleToday?.playlists.forEach(playlist => {
            const startTime = playlist.start_time.slice(0, 5); // Extract "HH:MM"
            const endTime = playlist.end_time.slice(0, 5);     // Extract "HH:MM"

            const startHour = parseInt(startTime.split(':')[0], 10); // Extract hours part
            const endHour = parseInt(endTime.split(':')[0], 10);     // Extract hours part

            // Assign playlist to corresponding standard hours in the schedule
            for (let hour = startHour; hour <= endHour; hour++) {
                const hourFormatted = hour.toString().padStart(2, '0') + ":00";

                // If this is the start hour of the playlist, ensure it's added to the correct minute
                if (hour === startHour) {
                    const startMinute = parseInt(startTime.split(':')[1], 10); // Extract minutes part
                    const startMinuteFormatted = hour.toString().padStart(2, '0') + ":" +
                        startMinute.toString().padStart(2, '0');
                    newQueue[startMinuteFormatted] = playlist;
                } else {
                    // Store the playlist object for this hour
                    newQueue[hourFormatted] = playlist;
                }
            }

            // Store the playlist object for the end time hash (HH:MM)
            const endTimeFormatted = endTime;
            newQueue[endTimeFormatted] = playlist;
        });
        dispatch(setQueue(newQueue));
    }

    function isTodayScheduleEmpty(device) {
        const [schedules] = splitDeviceChildren(device);
        const currentDay = format(new Date(), 'EEEE');
        
        // Turn all strings in array to uppercase, then do the check to prevent inconsistent values being missed
        const scheduleToday = schedules.find(schedule => schedule.day.map(day => day.toUpperCase()).includes(currentDay.toUpperCase()));
        return !scheduleToday
    }

    function getCurrentPlaylist() {
        if (!playlistQueue || Object.keys(playlistQueue).length === 0) {
            return null;
        }

        const currentTime = getCurrentTime();
        const keys = Object.keys(playlistQueue);

        // Sort keys based on HH:MM format
        keys.sort((a, b) => {
            const timeA = a.slice(0, 5); // Extract HH:MM from HH:MM:SS
            const timeB = b.slice(0, 5); // Extract HH:MM from HH:MM:SS
            return timeA.localeCompare(timeB);
        });

        // Initialize closest key
        let closestKey = null;

        // Find the closest key in time that is not ahead of current time
        keys.forEach(key => {
            // If key is ahead of current time, skip it
            if (key >= currentTime) return;

            // If closestKey is not set or current key is closer than closestKey
            if (!closestKey || key > closestKey) {
                closestKey = key;
            }
        });

        // If closestKey is an end_time, find the next key after it
        if (closestKey && playlistQueue[closestKey]) {
            const closestPlaylist = playlistQueue[closestKey];
            const playlistEndTime = closestPlaylist.end_time.slice(0, 5); // Extract HH:MM from HH:MM:SS

            if (playlistEndTime === closestKey) {
                // Find the index of closestKey in keys
                const index = keys.indexOf(closestKey);
                if (index !== -1 && index < keys.length - 1) {
                    closestKey = keys[index + 1];
                }
            }
        }

        return playlistQueue[closestKey] ?? null;
    }


    function getFullQueue() {
        return playlistQueue;
    }

    return {
        createQueue: createQueue,
        getCurrentPlaylist: getCurrentPlaylist,
        getFullQueue: getFullQueue,
        isTodayScheduleEmpty: isTodayScheduleEmpty
    };
};

export default QueueHandler;